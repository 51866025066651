<template>
  <svg width="24" height="22" viewBox="0 0 24 22"  class="z-icon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.11066L14.9664 7.9618L21.6 8.90586L16.8 13.4578L17.9328 19.8885L12 16.8507L6.06722 19.8885L7.20002 13.4578L2.40002 8.90586L9.03362 7.9618L12 2.11066Z" fill="white" stroke="white" stroke-width="3.75" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
    export default {
        name: "icon",
        props: ['type'],
        methods: {

        }
    }
</script>

<style scoped>

</style>
