import Vue from 'vue'
import App from './App.vue'
import Icon from './components/Icon/Icon'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.component('z-icon', Icon);
Vue.component('slick', Slick);

if(document.getElementById('z-quiz')){
  console.log('test')
  new Vue({
    render: h => h(App),
  }).$mount('#z-quiz')
}