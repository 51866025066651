<template>
  <section class="z-quiz">
    <div class="z-quiz--head">
      <h2 class="z-quiz--title">
        Подбираем идеальное кольцо для вас
      </h2>

      <button class="z-quiz--btn hidden-sm ml-a disabled" @click="endSession" v-if="question.choices[0] && question.choices[0].type !== 'advice'">
        Показать рекомендации
      </button>
      <button class="z-quiz--btn ml-a" @click.prevent="repeat" v-if="question.choices.length>1 && question.choices[0].type == 'advice'">
        Заново
      </button>
    </div>

    <div class="z-quiz--body">
      <p class="z-quiz--subtitle">
        Мы зададим несколько вопросов, чтобы лучше узнать ваши вкусы.
        Вы можете завершить тест в любой момент и всё равно получить нашу рекомендацию.
      </p>
      <h3 class="z-quiz--heading" v-show="!isloading">
        {{ question.title }}
      </h3>
    </div>

    <div class="loader" v-show="isloading">
      <div class="lds-dual-ring"></div>
    </div>
    <div v-show="!isloading">
      <slick
          class=""
          ref="slick"
          :options="slickOptions"
          v-if="question.choices.length>1"
      >
        <div class="z-quiz--item" v-for="choice in question.choices">
          <template>
            <div class="z-quiz--item-img">
              <a v-if="choice.offer.productUrl" target="_blank" :href="choice.offer.productUrl">
                <img :src="choice.offer.imageUrl" alt="pic">
              </a>
              <img :src="choice.offer.imageUrl" alt="pic" @click="checkButton(choice.button, choice)" v-else>
            </div>
            <p class="z-quiz--item-text">
                  <span v-for="param in choice.offer.params">
                    {{ param }}
                  </span>
              <span>
                    <b>
                      {{ choice.offer.price }} ₽
                    </b>
                  </span>
            </p>
            <a target="_blank" class="z-quiz--item-btn" :href="choice.offer.productUrl" v-if="choice.offer.productUrl">
              Карточка товара
            </a>
            <button v-else class="z-quiz--item-btn" @click="checkButton(choice.button, choice)">
              {{ choice.button ? choice.button : 'Карточка товара' }}
            </button>
          </template>
        </div>
      </slick>

      <div v-else class="z-quiz--items">
        <div class="z-quiz--item z-quiz--item_big" v-for="choice in question.choices">
          <template v-if="choice.type === 'rating'">
            <div class="z-quiz--rating-img">
              <img :src="choice.offer.imageUrl" alt="pic">
            </div>

            <div class="z-quiz--rating" :class="{active: checkedInput}">
              <label class="z-quiz--rating-item" v-for="n in choice.rateTo" :class="{active: n === checkedInput}">
                <input type="radio" name="rating" :value="n" @input="checkRate(n, choice)">
                <span class="z-quiz--rating-item--icon">
                    <z-icon></z-icon>
                </span>
              </label>
            </div>

            <p class="z-quiz--rating-text">
              Оцените от {{ choice.rateFrom }} до {{ choice.rateTo }}
            </p>
          </template>
          <template v-else>
            <div class="z-quiz--item-img">
              <img :src="choice.offer.imageUrl" alt="pic">
            </div>
            <p class="z-quiz--item-text">
                  <span v-for="param in choice.offer.params">
                    {{ param }}
                  </span>
              <span>
                    <b>
                      {{ choice.offer.price }} ₽
                    </b>
                  </span>
            </p>
            <button class="z-quiz--item-btn" @click="checkButton(choice.button, choice)">
              {{ choice.button ? choice.button : 'Карточка товара' }}
            </button>
          </template>
        </div>
      </div>

      <div class="z-quiz--footer">
        <button class="z-quiz--btn mr-a disabled" @click.prevent="back" v-if="question.choices.length>1 && question.choices[0].type !== 'advice'">
          Назад
        </button>

        <div class="z-quiz--slider-nav" v-if="question.choices.length>2">
          <button class="z-quiz--slider-nav--prev" @click.prev="prev"></button>

          <button class="z-quiz--btn" @click.prev="next">
            Посоветуйте ещё
          </button>

          <button class="z-quiz--slider-nav--next" @click.prev="next"></button>
        </div>
        <button v-else class="z-quiz--btn ml-a disabled" @click.prevent="skip">
          Пропустить вопрос
        </button>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      api: 'https://catalog-wizard.585.tmphost.ru/api/',
      //api: 'https://catalog-wizard.585.tmphost.ru/api/?qid=10005',
      //api: 'https://catalog-wizard.585.tmphost.ru/api/?qid=1001',
      checkedInput: false,
      isMobile: false,
      isPopup: false,
      isloading: true,
      question: {
        questionId: false,
        title: '',
        choices: [],
        parent: false,
      },
      slickOptions: {
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          // {
          //   breakpoint: 480,
          //   settings: {
          //     slidesToShow: 1,
          //     slidesToScroll: 1
          //   }
          // },
        ]
      },
    }
  },
  mounted: function () {
    let self = this;

    if (window.innerWidth < 769) {
      self.isMobile = true;
    }

    this.axios.get(this.api).then((response) => {
      self.isloading = false
      self.checkedInput = false
      self.question.questionId = response.data.questionId
      self.question.title = response.data.question
      self.question.choices = response.data.choices
    })

    window.addEventListener('resize', function () {
      if (window.innerWidth < 769) {
        self.isMobile = true;
      } else {
        self.isMobile = false;
      }
    })
  },
  methods: {
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    close() {
      let popup = document.querySelector('.z-quiz--popup')
      popup.classList.remove('is-open')
    },
    skip: function () {
      let self = this;
      self.isloading = true

      this.axios({
        method: 'post',
        url: this.api,
        data: {
          questionId: self.question.questionId,
          choice: 'skip',
        }
      }).then((response) => {
        self.isloading = false
        self.checkedInput = false
        self.question.questionId = response.data.questionId
        self.question.title = response.data.question
        self.question.choices = response.data.choices
        self.question.parent = response.data.parent
      })
    },
    endSession: function () {
      let self = this;
      self.isloading = true

      this.axios({
        method: 'post',
        url: this.api,
        data: {
          questionId: self.question.questionId,
          choice: 'finish',
        }
      }).then((response) => {
        self.isloading = false
        self.checkedInput = false
        self.question.questionId = response.data.questionId
        self.question.title = response.data.question
        self.question.choices = response.data.choices
        self.question.parent = response.data.parent
      })
    },
    checkRate: function (n, choice) {
      let self = this;
      self.question.choices = []
      self.isloading = true
      this.checkedInput = n
      this.axios({
        method: 'post',
        url: this.api,
        data: {
          questionId: self.question.questionId,
          choice: n,
        }
      }).then((response) => {
        self.isloading = false
        self.checkedInput = false
        self.question.questionId = response.data.questionId
        self.question.title = response.data.question
        self.question.choices = response.data.choices
        self.question.parent = response.data.parent
      })
    },
    checkButton: function (n, choice) {
      let self = this;
      self.question.choices = []
      self.isloading = true
      this.axios({
        method: 'post',
        url: this.api,
        data: {
          questionId: self.question.questionId,
          choice: n,
        }
      }).then((response) => {
        self.isloading = false
        self.checkedInput = false
        self.question.questionId = response.data.questionId
        self.question.title = response.data.question
        self.question.choices = response.data.choices
      })
    },
    back: function (n, choice) {
      let self = this;
      self.question.choices = []
      self.isloading = true
      this.axios({
        method: 'post',
        url: this.api,
        data: {
          questionId: self.question.parent.questionId,
          choice: self.question.parent.choices[0],
        }
      }).then((response) => {
        self.isloading = false
        self.checkedInput = false
        self.question.questionId = response.data.questionId
        self.question.title = response.data.question
        self.question.choices = response.data.choices
        self.question.parent = response.data.parent
      })
    },
    repeat: function (n, choice) {
      let self = this;
      self.question.choices = []
      self.isloading = true
      this.axios.get(this.api).then((response) => {
        self.isloading = false
        self.checkedInput = false
        self.question.questionId = response.data.questionId
        self.question.title = response.data.question
        self.question.choices = response.data.choices
      })
    },
    openPopup: function () {
      this.isPopup = true
      document.querySelector('body').classList.add('overflow')
    },
    closePopup: function () {
      this.isPopup = false;
      document.querySelector('body').classList.remove('overflow')
    },
    scrollTo: function (id) {
      document.querySelector(id).scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    },
  }
}
</script>

<style src="./assets/style/style.scss" lang="scss">

</style>

<style>
.disabled {
    display: none !important;
}
</style>